import styled from 'styled-components';

import { ReactComponent as MicroWaveSVG } from '~/assets/images/microwave.svg';
import { ReactComponent as WaveSVG } from '~/assets/images/wave.svg';

export const SectionProducts = styled.section`
	display: flex;
	width: 100%;
	position: relative;
	flex-direction: column;
	z-index: 2;
	background-color: transparent;
`;

export const Wave = styled(WaveSVG)`
	fill: ${({ theme }) => theme.colors.primary};
	background-color: transparent;
	border-color: ${({ theme }) => theme.colors.primary};
	transform: translateY(0.2rem);
`;

export const MicroWave = styled(MicroWaveSVG)`
	width: 100vw;
	fill: ${({ theme }) => theme.colors.primary};
	background-color: transparent;
	border-color: ${({ theme }) => theme.colors.primary};
	transform: translateY(-0.2rem);
`;

export const ProductsContent = styled.div`
	display: flex;
	width: 100%;
	padding-bottom: 11rem;
	background-color: ${({ theme }) => theme.colors.primary};
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export const ProductsTitle = styled.h3`
	display: flex;
	font-size: 3.8rem;
	font-weight: bold;
	text-transform: uppercase;
	padding: 1rem 0;
	margin-bottom: 3rem;
	color: ${({ theme }) => theme.colors.white};
	@media(max-width: 1000px) {
		font-size: 2.6rem;
		margin-bottom: 0;
		margin-top: 3rem;
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	max-width: 109rem;
	flex-direction: row;
	justify-content: space-between;
`;

export const Column = styled.div`
	display: flex;
	align-items: flex-start;
`;
