import { useState, useEffect } from 'react';

import ptBr from './translations/pt-BR.json';

export const translations = {
	'pt-BR': ptBr
};

export function useLocale(lang = '') {
	const [locale, setLocale] = useState('pt-BR');
	const locales = Object.keys(translations);

	useEffect(() => {
		if (locales.includes(lang)) {
			setLocale(lang);
		}
	// eslint-disable-next-line
	}, []);

	return { locale, setLocale };
}
