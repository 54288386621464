import { Link } from 'react-router-dom';

import styled from 'styled-components';

export const ProductBox = styled.button`
	display: flex;
	width: 26rem;
	height: 26rem;
	padding: 2.5rem;
	cursor: pointer;
	border-radius: 1rem;
	background-color: #e8ebf0;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
	@media(max-width: 1000px) {
		width: 20rem;
		height: 20rem;
	}
`;

export const ProductImage = styled.img`
	display: flex;
	width: 15rem;
	height: 15rem;
	@media(max-width: 1000px) {
		width: 10rem;
		height: 10rem;
	}
`;

export const ProductTitle = styled.h2`
	display: flex;
	font-size: 2rem;
	line-height: 2.7rem;
	color: ${({ theme }) => theme.colors.textMinDark};
	white-space: pre;
	@media(max-width: 1000px) {
		font-size: 1.6rem;
	}
`;

export const Column = styled(Link)`
	display: flex;
	flex-grow: 1;
	width: ${({ size }) => size};
	padding: 0.5rem;
	padding-bottom: ${({ paddingBottom }) => String(paddingBottom) || '0'}rem;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;
