import styled from 'styled-components';

export const Container = styled.section`
	display: flex;
	flex: 1;
	flex-direction: column;
`;

export const Section404 = styled.section`
	display: flex;
	width: 100%;
	padding-top: 5rem;
	padding-bottom: 10rem;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export const Box = styled.div`
	display: flex;
	position: relative;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export const Text = styled.h2`
	font-size: 4.8rem;
	font-weight: 800;
	line-height: 7.1rem;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.primary};
	position: absolute;
	top: 4rem;
	left: -1rem;
`;
export const ErrorCode = styled.h2`
	font-size: 28.8rem;
	font-weight: 800;
	line-height: 42.8rem;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.primary};
`;

export const ErrorTitle = styled.h1`
	display: flex;
	font-size: 3.8rem;
	line-height: 5.6rem;
	font-weight: 800;
	color: ${({ theme }) => theme.colors.textDark};
`;
export const ErrorText = styled.p`
	display: flex;
	font-size: 2.4rem;
	line-height: 3.3rem;
	color: ${({ theme }) => theme.colors.textDark};
`;
