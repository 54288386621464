import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Logo from '~/assets/images/logo.svg';
import {
	HeaderContent,
	MenuMobile,
	MenuLogo,
	MenuLogoImage,
	ButtonDrawer,
	MenuIconStyled,
	MenuCloseIconStyled,
	MenuLinksMobile,
	MenuTextMobile,
	DrawerStyled,
	MenuLogoMobile,
	ButtonCloseDrawer
} from '~/common/styleds/components/header';

const HeaderMobile = ({ links = [] }) => {
	const intl = useIntl();
	const [openDrawer, setOpenDrawer] = useState(false);
	return (
		<HeaderContent>
			<MenuMobile>
				<Link to='/'>
					<MenuLogo>
						<MenuLogoImage
							src={Logo}
							alt={intl.formatMessage({ id: 'header.altLogo' })}
						/>
					</MenuLogo>
				</Link>
				<ButtonDrawer onClick={() => setOpenDrawer(!openDrawer)}>
					<MenuIconStyled style={{ fontSize: 30 }} />
				</ButtonDrawer>
			</MenuMobile>
			<DrawerStyled
				anchor='right'
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
			>
				<Link to='/'>
					<MenuLogoMobile>
						<MenuLogoImage
							src={Logo}
							style={{ width: 120, height: 120 }}
							alt={intl.formatMessage({ id: 'header.altLogo' })}
						/>
					</MenuLogoMobile>
				</Link>
				<ButtonCloseDrawer onClick={() => setOpenDrawer(false)}>
					<MenuCloseIconStyled style={{ fontSize: 30 }} />
				</ButtonCloseDrawer>
				<MenuLinksMobile>
					{links.map((link, index) => (
						<Link
							key={link.key}
							to={link.to}
							style={{
								width: '100vw',
								paddingTop: index === 0 ? 30 : 15,
								paddingBottom: 15,
								justifyContent: 'center',
								alignItems: 'center',
								alignContent: 'center',
								display: 'flex',
								background:
									index % 2 === 0 ? '#97030C' : 'transparent'
							}}
						>
							<MenuTextMobile active={link.active}>
								{link.text}
							</MenuTextMobile>
						</Link>
					))}
				</MenuLinksMobile>
			</DrawerStyled>
		</HeaderContent>
	);
};

export default HeaderMobile;
