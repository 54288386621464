import styled from 'styled-components';

export const ProductBox = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
`;

export const ProductImg = styled.img`
	display: flex;
	width: 29rem;
	height: 29rem;
	border-radius: 100%;
	padding: 0.5rem;
	@media(max-width: 1000px) {
		width: 18rem;
		height: 18rem;
		padding: 0.3rem;
	}
`;

export const ProductTitle = styled.h2`
	font-size: 2.8rem;
	font-weight: 800;
	width: 21rem;
	text-align: center;
	color: ${({ theme }) => theme.colors.white};
	margin-top: 1rem;
	@media(max-width: 1000px) {
		font-size: 2rem;
		margin-top: 0;
	}
`;

export const ProductText = styled.h2`
	font-size: 1.8rem;
	line-height: 2.5rem;
	width: 21rem;
	text-align: center;
	color: ${({ theme }) => theme.colors.white};
	margin-top: 1rem;
	@media(max-width: 1000px) {
		font-size: 1.6rem;
		margin-top: .5rem;
	}
`;
