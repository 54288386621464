import styled from 'styled-components';

export const FooterSection = styled.footer`
	display: flex;
	width: 100vw;
	flex-direction: column;
	position: relative;
`;

export const FooterContent = styled.div`
	display: flex;
	width: 100vw;
	padding-bottom: 5rem;
	background-color: ${({ theme }) => theme.colors.primary};
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
`;

export const FooterColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const LogoImg = styled.img`
	display: flex;
	@media(max-width: 1000px) {
		width: 14rem;
		height: 14rem;
		margin-bottom: -2rem;
	}
`;

export const FooterTitle = styled.h3`
	display: flex;
	font-size: 2.4rem;
	font-weight: bold;
	padding: 1rem 0;
	color: ${({ theme }) => theme.colors.white};
	@media(max-width: 1000px) {
		font-size: 1.8rem;
	}
`;

export const FooterText = styled.span`
	display: flex;
	max-width: 27rem;
	font-size: 1.8rem;
	line-height: 2.5rem;
	font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
	white-space: pre-line;
	padding: 0.5rem 0;
	color: ${({ theme }) => theme.colors.white};
	align-items: center;
	text-align: center;
	@media(max-width: 1000px) {
		font-size: 1.6rem;
	}
`;

export const Copyright = styled.div`
	display: flex;
	width: 100%;
	padding: 2rem;
	background-color: ${({ theme }) => theme.colors.primaryLight};
	justify-content: center;
	align-items: center;
	align-content: center;
`;

export const CopyrightText = styled.span`
	display: flex;
	font-size: 1.8rem;
	color: ${({ theme }) => theme.colors.white};
`;
