import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import styled from 'styled-components';

export const Container = styled.section`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
	z-index: 2;
`;
export const Content = styled.div`
	display: flex;
	width: 100vw;
	padding-top: 5rem;
	padding-bottom: 10rem;
	background-color: #fff;
	align-content: center;
	align-items: center;
	justify-content: center;
	@media (max-width: 1000px) {
		padding-top: 2rem;
	}
`;

export const ProductTitle = styled.h1`
	font-size: 3.8rem;
	line-height: 5.6rem;
	font-weight: 800;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.textDark};
	margin-bottom: 1.5rem;
	@media (max-width: 1000px) {
		font-size: 2.4rem;
		margin-bottom: 0.5rem;
		line-height: normal;
	}
`;

export const ProductSubTitle = styled.h3`
	font-size: 2.4rem;
	line-height: 3.3rem;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.textDark};
	margin-bottom: 2rem;
	@media (max-width: 1000px) {
		font-size: 2rem;
		margin-bottom: 1rem;
	}
`;

export const ProductDescription = styled.p`
	font-size: 1.8rem;
	line-height: 2.5rem;
	text-align: justify;
	color: ${({ theme }) => theme.colors.textDark};
	margin-bottom: 5rem;
	@media (max-width: 1000px) {
		font-size: 1.6rem;
		margin-bottom: 1.5rem;
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100vw;
	max-width: 109rem;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	align-items: flex-start;
	padding-bottom: ${({ paddingBottom }) => paddingBottom};
	justify-content: space-between;
`;

export const Column = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	width: ${({ size }) => size};
	padding: 2rem;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
	@media (max-width: 1000px) {
		width: 100%;
	}
`;

export const ProductImage = styled.img`
	display: flex;
	width: 100%;
	max-width: 45rem;
	height: auto;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

export const Accordion = styled(MuiAccordion)`
	border-radius: 1rem;
	margin-bottom: 2rem;

	::before {
		background-color: transparent !important;
	}
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
	border-radius: 1rem;
	background-color: ${({ theme }) => theme.colors.primary};

	&.Mui-expanded {
		min-height: inherit;
	}

	.MuiAccordionSummary-content {
		margin: 1.2rem 0;
	}
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
	display: flex;
	flex-direction: column;
	background-color: #f3f3f3;
`;

export const AccordionDetailsHeader = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem;
	flex-direction: row;
	justify-content: space-between;
`;

export const AccordionSummaryTitle = styled.span`
	font-size: 2.4rem;
	color: white;
	font-weight: bold;
	@media (max-width: 1000px) {
		font-size: 1.6rem;
	}
`;

export const AccordionDetailsHeaderText = styled.h4`
	display: flex;
	font-size: 1.8rem;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 2.5rem;
	color: #593a3a;
	@media (max-width: 1000px) {
		font-size: 1.2rem;
	}
`;
export const AccordionDetailsHeaderTextPercent = styled.span`
	display: flex;
	font-size: 1.8rem;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 2.5rem;
	color: #593a3a;
	margin-left: 2rem;
	@media (max-width: 1000px) {
		font-size: 1.2rem;
	}
`;

export const AccordionDetailsContent = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem;
	flex-direction: column;
	justify-content: space-between;
`;

export const AccordionDetailsItem = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
`;

export const AccordionDetailsItemText = styled.h5`
	display: flex;
	font-size: 1.8rem;
	line-height: 2.5rem;
	text-transform: uppercase;
	color: #593a3a;
	@media (max-width: 1000px) {
		font-size: 1.2rem;
	}
`;

export const AccordionDetailsItemTextPercent = styled.span`
	display: flex;
	width: 6rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	line-height: 2.5rem;
	color: #593a3a;
	margin-left: 2rem;
	justify-content: flex-end;
	@media (max-width: 1000px) {
		font-size: 1.2rem;
		margin-left: 1rem;
		width: 4rem;
	}
`;

export const AccordionDetailsFooter = styled.div`
	display: flex;
	width: 100%;
	padding: 1rem;
	flex-direction: row;
	justify-content: space-between;
`;

export const AccordionDetailsFooterText = styled.h4`
	display: flex;
	font-size: 1.4rem;
	font-weight: 400;
	line-height: 1.9rem;
	color: #593a3a;
	white-space: pre-line;
	@media (max-width: 1000px) {
		font-size: 1rem;
	}
`;

export const ButtonBack = styled.button`
	width: fit-content;
	padding: 1rem 4rem;
	border-radius: 1rem;
	background-color: ${({ theme }) => theme.colors.primaryDark};
	color: ${({ theme }) => theme.colors.white};
	text-transform: uppercase;
	font-size: 2.4rem;
	font-weight: 800;
	line-height: 3.6rem;
	@media (max-width: 1000px) {
		font-size: 1.6rem;
		padding: .5rem 2rem;
	}
`;
