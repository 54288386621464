import React from 'react';
import { IntlProvider } from 'react-intl';


import CssBaseline from '@material-ui/core/CssBaseline';
import {
	ThemeProvider as MuiThemeProvider,
	StylesProvider
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import { muiTheme, theme } from '~/common/styleds';
import { useLocale, translations } from '~/locale';


import Routes from './Routes';

import '~/assets/css/reset.css';

const App = () => {
	const { locale } = useLocale(navigator.language);
	return (
		<StylesProvider injectFirst>
			<ThemeProvider theme={theme}>
				<MuiThemeProvider theme={muiTheme}>
					<IntlProvider
						locale={locale}
						messages={translations[locale]}
					>
						<Routes />
						<CssBaseline />
					</IntlProvider>
				</MuiThemeProvider>
			</ThemeProvider>
		</StylesProvider>
	);
};

export default App;
