import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { Home, About, Products, Product, Contact, Error404 } from '../pages';

const Routes = () => {
	return (
		<Router>
			<ToastProvider
				autoDismiss
				autoDismissTimeout={6000}
				placement="top-center"
			>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/quem-somos' component={About} />
					<Route exact path='/produtos' component={Products} />
					<Route path='/produto/:slug' component={Product} />
					<Route exact path='/contato' component={Contact} />
					<Route path='*' component={Error404} />
				</Switch>
			</ToastProvider>
		</Router>
	);
};

export default Routes;
