import styled from 'styled-components';

export const SectionMission = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 8rem 0;
	z-index: 1;
	background-color: ${({ theme }) => theme.colors.primary};
	justify-content: center;
	align-content: center;
	align-items: center;
	@media(max-width: 1000px) {
		padding-top: 5rem;
	}
`;

export const BoxMissionText = styled.div`
	display: flex;
	flex-direction: column;
	white-space: pre-wrap;
	padding: 2rem 0;
`;

export const TitleMission = styled.h1`
	display: flex;
	font-size: 3.8rem;
	font-weight: 600;
	line-height: 5.6rem;
	color: ${({ theme }) => theme.colors.white};
	@media(max-width: 1000px) {
		font-size: 2.8rem;
	}
`;

export const TextMission = styled.p`
	display: flex;
	font-size: 2.4rem;
	line-height: 3.3rem;
	color: ${({ theme }) => theme.colors.white};
	@media(max-width: 1000px) {
		font-size: 1.8rem;
	}
`;

export const ImageAbout = styled.img`
	width: 50rem;
	height: 50rem;
	border-radius: 100%;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	max-width: 109rem;
	flex-direction: row;
	justify-content: space-between;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 3rem;
`;
