import React from 'react';
import { FormattedMessage } from 'react-intl';

import Acai from '~/assets/images/imagem_quemsomos.png';
import {
	SectionMission,
	Row,
	Column,
	BoxMissionText,
	TitleMission,
	TextMission,
	ImageAbout
} from '~/common/styleds/components/mission';
import { useWindowSize } from '~/hooks/window';

const Mission = () => {
	const { width } = useWindowSize();
	return (
		<SectionMission>
			<Row>
				<Column>
					<BoxMissionText>
						<TitleMission>
							<FormattedMessage id='about.mission.title' />
						</TitleMission>
						<TextMission>
							<FormattedMessage id='about.mission.text' />
						</TextMission>
					</BoxMissionText>
					<BoxMissionText>
						<TitleMission>
							<FormattedMessage id='about.vision.title' />
						</TitleMission>
						<TextMission>
							<FormattedMessage id='about.vision.text' />
						</TextMission>
					</BoxMissionText>
					<BoxMissionText>
						<TitleMission>
							<FormattedMessage id='about.values.title' />
						</TitleMission>
						<TextMission>
							<FormattedMessage id='about.values.text' />
						</TextMission>
					</BoxMissionText>
				</Column>
				{width >= 1000 && (
					<Column>
						<ImageAbout src={Acai} />
					</Column>
				)}
			</Row>
		</SectionMission>
	);
};

export default Mission;
