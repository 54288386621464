import styled from 'styled-components';

import { ReactComponent as MicroWaveSVG } from '~/assets/images/microwave.svg';

export const MicroWave = styled(MicroWaveSVG)`
	width: 100vw;
	fill: ${({ theme }) => theme.colors.primary};
	background-color: transparent;
	border-color: ${({ theme }) => theme.colors.primary};
	transform: rotateX(180deg) translateY(-.2rem);

`;
export const SectionInfoContact = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	z-index: 1;
	justify-content: center;
	align-content: center;
	align-items: center;
`;

export const InfoContactContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	z-index: 1;
	padding: 15rem 0;
	background-color: ${({ theme }) => theme.colors.primary};
	justify-content: center;
	align-content: center;
	align-items: center;
`;

export const BoxInfoContactText = styled.div`
	display: flex;
	/* width: 250px; */
	flex-direction: column;
	padding: 2rem 0;
`;

export const TitleInfoContact = styled.h1`
	display: flex;
	width: fit-content;
	font-size: 2.1rem;
	line-height: 3.3rem;
	font-weight: bold;
	color: ${({ theme }) => theme.colors.white};
`;

export const TextInfoContact = styled.h3`
	display: flex;
	width: fit-content;
	font-size: 2.1rem;
	line-height: 3.3rem;
	color: ${({ theme }) => theme.colors.white};
	white-space: pre;
	@media(max-width: 1000px) {
		white-space: pre;
	}
`;

export const LinkInfoContact = styled.a`
	display: flex;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	width: fit-content;
	font-size: 2.1rem;
	line-height: 3.3rem;
	color: ${({ theme }) => theme.colors.white};
	white-space: pre-line;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	max-width: 109rem;
	flex-direction: row;
	justify-content: space-between;
`;

export const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
`;
