import React from 'react';
import { Link } from 'react-router-dom';

import {
	ProductBox,
	ProductTitle,
	ProductImage
} from '~/common/styleds/components/product';

const Product = ({ product }) => {
	return (
		<Link
			style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'center',
				alignContent: 'center',
				alignItems: 'center'
			}}
			to={{ pathname: `/produto/${product.slug}`, state: product }}
		>
			<ProductBox>
				<ProductImage src={product.img} />
				<ProductTitle>{product.title}</ProductTitle>
			</ProductBox>
		</Link>
	);
};

export default Product;
