import MaskedInput from 'react-input-mask';

import styled from 'styled-components';

export const SectionFormContact = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	z-index: 2;
`;

export const FormContactContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 5rem 0;
	background-color: ${({ theme }) => theme.colors.white};
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
	transform: translateY(-.1rem);
`;

export const BoxText = styled.div`
	display: flex;
	padding: 2rem 0;
	flex-direction: column;
`;

export const Title = styled.h1`
	display: flex;
	font-size: 3.8rem;
	font-weight: 800;
	text-transform: uppercase;
	line-height: 5.6rem;
	margin-bottom: 1rem;
	color: ${({ theme }) => theme.colors.textDark};
`;

export const Text = styled.p`
	display: flex;
	font-size: 1.8rem;
	line-height: 2.5rem;
	color: ${({ theme }) => theme.colors.textDark};
`;

export const Form = styled.form`
	display: flex;
	max-width: 94rem;
	width: 100%;
	padding: 12px;
	background-color: ${({ theme }) => theme.colors.white};
	flex-direction: column;
`;

export const Input = styled.input`
	display: flex;
	width: 100%;
	font-size: 1.8rem;
	padding: 1rem 2rem;
	border: 1px solid ${({ theme }) => theme.colors.inputBorder};
	border-radius: 1rem;
`;

export const InputMask = styled(MaskedInput)`
	display: flex;
	width: 100%;
	font-size: 1.8rem;
	padding: 1rem 2rem;
	border: 1px solid ${({ theme }) => theme.colors.inputBorder};
	border-radius: 1rem;
`;

export const TextArea = styled.textarea`
	display: flex;
	width: 100%;
	font-size: 1.8rem;
	padding: 1rem 2rem;
	border: 1px solid ${({ theme }) => theme.colors.inputBorder};
	border-radius: 1rem;
`;

export const Button = styled.button`
	font-size: 2.4rem;
	line-height: 3.3rem;
	color: ${({ theme }) => theme.colors.white};
	padding: 1rem 5rem;
	border-radius: 1rem;
	margin: 12px;
	background-color: ${({ theme }) => theme.colors.primaryDark};
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
`;

export const Column = styled.div`
	display: flex;
	flex-grow: 1;
	width: ${({ size }) => size};
	padding: 0.5rem;
	padding-bottom: ${({ paddingBottom }) => String(paddingBottom) || '0'}rem;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;
