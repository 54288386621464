import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';

import Logo from '~/assets/images/logo.svg';
import { useTheme } from '~/common/styleds';
import {
	HeaderContent,
	Menu,
	MenuNav,
	MenuText,
	MenuLinks,
	MenuContact,
	MenuContactPhone,
	MenuLogo,
	MenuLogoImage,
	MenuSocialMedia,
	MenuSocialMediaA
} from '~/common/styleds/components/header';
import { useWindowSize } from '~/hooks/window';

import Icon from '../icon/Icon';
import HeaderMobile from './HeaderMobile';

const Header = () => {
	const { pathname } = useLocation();
	const intl = useIntl();
	const theme = useTheme();
	const { width } = useWindowSize();
	const links = [
		{
			key: intl.formatMessage({ id: 'header.menu.home.key' }),
			to: '/',
			text: intl.formatMessage({ id: 'header.menu.home.text' }),
			active: pathname === '/'
		},
		{
			key: intl.formatMessage({ id: 'header.menu.about.key' }),
			to: '/quem-somos',
			text: intl.formatMessage({ id: 'header.menu.about.text' }),
			active: pathname === '/quem-somos'
		},
		{
			key: intl.formatMessage({ id: 'header.menu.products.key' }),
			to: '/produtos',
			text: intl.formatMessage({ id: 'header.menu.products.text' }),
			active: pathname === '/produtos'
		},
		{
			key: intl.formatMessage({ id: 'header.menu.contact.key' }),
			to: '/contato',
			text: intl.formatMessage({ id: 'header.menu.contact.text' }),
			active: pathname === '/contato'
		}
	];

	if (width <= 1000) {
		return <HeaderMobile links={links} />;
	}

	return (
		<HeaderContent>
			<Menu>
				<Link to='/'>
					<MenuLogo>
						<MenuLogoImage
							src={Logo}
							alt={intl.formatMessage({ id: 'header.altLogo' })}
						/>
					</MenuLogo>
				</Link>
				<MenuNav>
					<MenuLinks>
						{links.map((link) => (
							<Link key={link.key} to={link.to}>
								<MenuText active={link.active}>
									{link.text}
								</MenuText>
							</Link>
						))}
					</MenuLinks>
					<MenuContact>
						<Icon
							name='phone'
							color={theme.colors.white}
							size={36}
						/>
						<MenuContactPhone>
							<FormattedMessage id='header.menu.phone' />
						</MenuContactPhone>
					</MenuContact>
					<MenuSocialMedia>
						<MenuSocialMediaA href='https://www.facebook.com/LaNieveacai' target='blank'>
							<Icon
								name='facebook'
								color={theme.colors.white}
								size={36}
							/>
						</MenuSocialMediaA>
						<MenuSocialMediaA href='https://www.instagram.com/lanieveacai' target='blank'>
							<Icon
								name='instagram'
								color={theme.colors.white}
								size={36}
							/>
						</MenuSocialMediaA>
					</MenuSocialMedia>
				</MenuNav>
			</Menu>
		</HeaderContent>
	);
};

export default Header;
