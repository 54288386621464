import React, { useState } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import 'react-awesome-slider/dist/styles.css';
import img1 from '~/assets/images/bannerHome/img1.png';
import img2 from '~/assets/images/bannerHome/img2.png';
import img3 from '~/assets/images/bannerHome/img3.png';
import img4 from '~/assets/images/bannerHome/img4.png';
import { BannerSection } from '~/common/styleds/components/banner';

import '~/assets/css/custom-awesome-slider.css';

const AutoplayAwesomeSlider = withAutoplay(AwesomeSlider);

const Banner = () => {
	const [play, setPlay] = useState(true);
	return (
		<BannerSection
			onMouseEnter={() => setPlay(false)}
			onMouseLeave={() => setPlay(true)}
		>
			<AutoplayAwesomeSlider
				style={{
					position: 'absolute',
					width: '100vw',
					height: 'calc(100vw * .3576680672)',
					top: 0
				}}
				mobileTouch
				bullets={false}
				play={play}
				interval={3000}
				media={[
					{ source: img1 },
					{ source: img2 },
					{ source: img3 },
					{ source: img4 }
				]}
			/>
		</BannerSection>
	);
};

export default Banner;
