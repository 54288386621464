import { Link } from 'react-router-dom';

import styled from 'styled-components';

import Banner from '~/assets/images/bannerQuality.png';

export const GoContactSection = styled(Link)`
	display: flex;
	width: 100%;
	height: 100%;
	/* transform: translateY(calc(calc(100vw * -.06769888794) - 1px)); */
	align-content: center;
	align-items: center;
	justify-content: center;
	background-image: ${`url(${Banner})`};
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	z-index: -1;
`;

export const Expanded = styled.img`
	width: 100%;
	// 	height: 50px;
`;
