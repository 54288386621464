import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';

import BannerFooter from '~/assets/images/bannerFooter/detalhedeprodutos.png';
import { Header, BannerColor, Footer, Icon } from '~/common/components';
import { useTheme } from '~/common/styleds';
import {
	Container,
	Content,
	Row,
	Column,
	ProductTitle,
	ProductSubTitle,
	ProductDescription,
	ProductImage,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	AccordionDetailsHeader,
	AccordionDetailsHeaderText,
	AccordionDetailsHeaderTextPercent,
	AccordionDetailsContent,
	AccordionDetailsItem,
	AccordionDetailsItemText,
	AccordionDetailsItemTextPercent,
	AccordionDetailsFooter,
	AccordionDetailsFooterText,
	ButtonBack,
	AccordionSummaryTitle
} from '~/common/styleds/product/product';
import products from '~/data/products';
import { useWindowSize } from '~/hooks/window';

const Product = () => {
	const { colors } = useTheme();
	const { slug: urlSlug } = useParams();
	const history = useHistory();
	const [expanded, setExpanded] = useState(false);
	const [product, setProduct] = useState();
	const [loading, setLoading] = useState(true);
	const { width } = useWindowSize();

	useEffect(() => {
		const p = products.filter((p) => p.slug === urlSlug);
		setProduct(p[0]);
		setLoading(false);
		// eslint-disable-next-line
	}, []);

	const handleChangeAccordion = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const renderNutritionsTable = () => {
		return product.nutritionTable.map((nutrition) => {
			console.log(nutrition);
			return (
				<Accordion
					key={nutrition.slug}
					expanded={expanded === nutrition.title}
					onChange={handleChangeAccordion(nutrition.title)}
				>
					<AccordionSummary
						expandIcon={
							<Icon
								name='expand'
								color={colors.white}
								size={width > 1000 ? 24 : 16}
								marginRight='1rem'
								marginLeft='1rem'
							/>
						}
					>
						<AccordionSummaryTitle>
							{nutrition.title}
						</AccordionSummaryTitle>
					</AccordionSummary>
					<AccordionDetails>
						{nutrition.table.header && (
							<AccordionDetailsHeader>
								<AccordionDetailsHeaderText>
									{nutrition.table.header.key}
								</AccordionDetailsHeaderText>
								<AccordionDetailsHeaderText>
									{nutrition.table.header.value}
									<AccordionDetailsHeaderTextPercent>
										{nutrition.table.header.percent}
									</AccordionDetailsHeaderTextPercent>
								</AccordionDetailsHeaderText>
							</AccordionDetailsHeader>
						)}
						<AccordionDetailsContent>
							{nutrition.table.content.map((item, index) => (
								<AccordionDetailsItem
									key={nutrition.slug + index.toString()}
								>
									<AccordionDetailsItemText>
										{item.key}
									</AccordionDetailsItemText>
									{item.value && (
										<AccordionDetailsItemText>
											{item.value}
											<AccordionDetailsItemTextPercent>
												{item.percent}
											</AccordionDetailsItemTextPercent>
										</AccordionDetailsItemText>
									)}
								</AccordionDetailsItem>
							))}
						</AccordionDetailsContent>
						<AccordionDetailsFooter>
							<AccordionDetailsFooterText>
								{nutrition.table.footer}
							</AccordionDetailsFooterText>
						</AccordionDetailsFooter>
					</AccordionDetails>
				</Accordion>
			);
		});
	};

	if (loading) {
		return <h1>Loading...</h1>;
	}
	return (
		<>
			<Helmet>
				<title>La Nieve Açaí e Sorvetes | {product.title} </title>
				<meta
					name='description'
					content={`${product.description} Tamanhos: ${
						product.subtitle
					}. ${
						product.tag === 'sorvete'
							? `Sabores: ${product.nutritionTable[0].table.content
								.map((item) => item.key)
								.join(', ')}`
							: ''
					}`}
				/>
			</Helmet>
			<Header />
			<BannerColor />
			<Container>
				<Content>
					<Row>
						<Column size='50%' justifyContent='flex-start'>
							<ProductTitle>{product.title}</ProductTitle>
							<ProductSubTitle>
								{product.subtitle}
							</ProductSubTitle>
							{width <= 1000 && (
								<ProductImage src={product.img} />
							)}
							<ProductDescription>
								{product.text}
							</ProductDescription>
							{product.nutritionTable.length > 0 ? (
								<>
									{product.nutritionTableTitle && (
										<ProductTitle>
											Tabela Nutricional
										</ProductTitle>
									)}
									{renderNutritionsTable()}
								</>
							) : null}

							<ButtonBack
								onClick={() => history.push('/produtos')}
							>
								Voltar
							</ButtonBack>
						</Column>
						{width > 1000 && (
							<Column size='50%' justifyContent='flex-end'>
								<ProductImage src={product.img} />
							</Column>
						)}
					</Row>
				</Content>
				<Footer img={BannerFooter} path='/contato' />
			</Container>
		</>
	);
};

export default Product;
