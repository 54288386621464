import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid } from '@material-ui/core';

import Acai from '~/assets/images/resumeProduct/acai.png';
import Calda from '~/assets/images/resumeProduct/calda.png';
import Sorvete from '~/assets/images/resumeProduct/sorvete.png';
import {
	SectionProducts,
	Wave,
	// MicroWave,
	ProductsContent,
	ProductsTitle
} from '~/common/styleds/components/resumeProducts';

import ResumeProductsItem from '../resumeProductsItem/ResumeProductsItem';

const ResumeProducts = () => {
	const { formatMessage } = useIntl();
	return (
		<SectionProducts>
			<Wave style={{ width: '100vw' }} />
			<ProductsContent>
				<ProductsTitle>
					<FormattedMessage id='home.products.title' />
				</ProductsTitle>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={4}>
						<ResumeProductsItem
							title={formatMessage({
								id: 'home.products.product1.title'
							})}
							text={formatMessage({
								id: 'home.products.product1.text'
							})}
							query='açaí'
							img={Acai}
							imgAlt={formatMessage({
								id: 'home.products.product1.imgAlt'
							})}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4}>
						<ResumeProductsItem
							title={formatMessage({
								id: 'home.products.product2.title'
							})}
							text={formatMessage({
								id: 'home.products.product2.text'
							})}
							img={Sorvete}
							query='sorvete'
							imgAlt={formatMessage({
								id: 'home.products.product2.imgAlt'
							})}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4}>
						<ResumeProductsItem
							title={formatMessage({
								id: 'home.products.product3.title'
							})}
							text={formatMessage({
								id: 'home.products.product3.text'
							})}
							query='calda'
							img={Calda}
							imgAlt={formatMessage({
								id: 'home.products.product3.imgAlt'
							})}
						/>
					</Grid>
				</Grid>
			</ProductsContent>
		</SectionProducts>
	);
};

export default ResumeProducts;
