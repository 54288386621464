import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import {
	ProductBox,
	ProductImg,
	ProductTitle,
	ProductText
} from '~/common/styleds/components/resumeProductsItem';

const ResumeProductsItem = ({ title, text, img, imgAlt, query }) => {
	return (
		<ProductBox>
			<Link
				to={{
					pathname: '/produtos',
					search: `categoria=${query}`
				}}
			>
				<ProductImg src={img} alt={imgAlt} />
			</Link>
			<Link
				to={{
					pathname: '/produtos',
					search: `categoria=${query}`
				}}
			>
				<ProductTitle>{title}</ProductTitle>
			</Link>
			<ProductText>{text}</ProductText>
		</ProductBox>
	);
};

ResumeProductsItem.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	img: PropTypes.any.isRequired,
	imgAlt: PropTypes.string.isRequired
};

export default ResumeProductsItem;
