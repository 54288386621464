import React from 'react';
import { Helmet } from 'react-helmet';

import BannerFooter from '~/assets/images/bannerFooter/quemsomosecontato.png';
import {
	Header,
	BannerColor,
	FormContact,
	InfoContact,
	Footer
} from '~/common/components';

const Contact = () => {
	return (
		<div>
			<Header />
			<Helmet>
				<title>La Nieve Açaí e Sorvetes - Contato</title>
				<meta
					name='description'
					content=' Para comprar nossos produtos entre em contato com um de nossos revendedores ou envie uma mensagem pelo nosso formulário.'
				/>
			</Helmet>
			<BannerColor />
			<FormContact />
			<InfoContact />
			<Footer img={BannerFooter} path='/produtos' />
		</div>
	);
};

export default Contact;
