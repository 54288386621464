import styled from 'styled-components';

import { ReactComponent as Wave } from '~/assets/images/wave.svg';

export const SectionHistory = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	z-index: 2;
`;

export const StyledWave = styled(Wave)`
	width: 100vw;
	fill: white;
	margin-bottom: -3px;
	background-color: #db2430;
`;

export const HistoryContainer = styled.div`
	display: flex;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export const HistoryContent = styled.div`
	display: flex;
	max-width: 94rem;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	flex-direction: column;
`;

export const HistoryTitle = styled.h1`
	display: flex;
	font-size: 3.8rem;
	font-weight: bold;
	text-transform: uppercase;
	padding: 1rem 0;
	margin-bottom: 1.8rem;
	color: ${({ theme }) => theme.colors.textDark};
	text-align: center;
	@media(max-width: 1000px) {
		font-size: 2.8rem;
	}
`;

export const HistoryParagraph = styled.p`
	display: flex;
	font-size: 1.8rem;
	line-height: 2.5rem;
	color: ${({ theme }) => theme.colors.textDark};
	margin-bottom: 1.8rem;
	@media(max-width: 1000px) {
		font-size: 1.6rem;
	}
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-around;
`;

export const Column = styled.div`
	display: flex;
`;
