import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import {
	SectionCategoryProducts,
	CategoryProductsContainer,
	CategoryProductsTitle,
	Row,
	Column,
	CategoryProductsText,
	ButtonCategory,
} from '~/common/styleds/components/categoryProducts';
import products from '~/data/products';
import { useWindowSize } from '~/hooks/window';

import Product from './Product';

const CategoryProducts = () => {
	const { search } = useLocation();
	const categoryQuery =
		decodeURIComponent(search).split('categoria=')[1] ?? '';
	const [category, setCategory] = useState(categoryQuery);
	const { width } = useWindowSize();

	const renderByCategory = () => {
		if (category === '') {
			return products.map((p, index) => (
				<Grid
					item
					xs={12}
					sm={6}
					md={3}
					key={index}
					style={{ marginBottom: 10 }}
				>
					<Product key={index} product={{ ...p, padbottom: '2.5' }} />
				</Grid>
			));
		}
		const productsCategory = products.filter((p) => p.tag === category);
		return productsCategory.map((p, index) => (
			<Grid
				item
				xs={12}
				sm={6}
				md={3}
				key={index}
				style={{ marginBottom: 10 }}
			>
				<Product product={{ ...p, padbottom: '2.5' }} />
			</Grid>
		));
	};

	return (
		<SectionCategoryProducts>
			<CategoryProductsContainer>
				<Grid container spacing={3}>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Row>
							<CategoryProductsTitle>
								<FormattedMessage id='products.category.title' />
							</CategoryProductsTitle>
						</Row>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Row>
							<CategoryProductsText>
								<FormattedMessage id='products.category.p1' />
								<FormattedMessage id='products.category.p2' />
							</CategoryProductsText>
						</Row>
					</Grid>
					{width > 1000 && (
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<Row
								justifyContent='space-evenly'
								paddingBottom='5rem'
							>
								<Column>
									<ButtonCategory
										selected={category === 'açaí'}
										onClick={() => setCategory('açaí')}
									>
										Açaís
									</ButtonCategory>
								</Column>
								<Column>
									<ButtonCategory
										selected={category === 'sorvete'}
										onClick={() => setCategory('sorvete')}
									>
										Sorvetes
									</ButtonCategory>
								</Column>
								<Column>
									<ButtonCategory
										selected={category === 'calda'}
										onClick={() => setCategory('calda')}
									>
										Caldas
									</ButtonCategory>
								</Column>
							</Row>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						sm={12}
						md={12}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center',
						}}
					>
						<Row>{renderByCategory()}</Row>
					</Grid>
				</Grid>
			</CategoryProductsContainer>
		</SectionCategoryProducts>
	);
};

export default CategoryProducts;
