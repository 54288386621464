import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import styled from 'styled-components';

export const HeaderContent = styled.div`
	display: flex;
	width: 100%;
	z-index: 99999999;
`;

export const Menu = styled.div`
	display: flex;
	width: 100%;
	height: 6rem;
	align-items: center;
	align-content: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const MenuNav = styled.div`
	display: flex;
	width: 100%;
	max-width: 109rem;
	height: 6rem;
	align-content: center;
	align-items: center;
	justify-content: space-evenly;
`;

export const MenuLogo = styled.div`
	display: flex;
	width: fit-content;
	height: 6rem;
	align-items: center;
`;

export const MenuLogoMobile = styled.div`
	display: flex;
	width: 100%;
	height: 8rem;
	align-items: center;
`;

export const MenuLogoImage = styled.img`
	transform: translateY(23%);
	width: 20rem;
	height: 20rem;
	@media(max-width: 1000px) {
		width: 11rem;
		height: 11rem;
		transform: translateY(0px);
	}
	z-index: 999;
`;

export const MenuLinks = styled.div`
	display: flex;
	width: fit-content;
	height: 6rem;
	align-items: center;
`;

export const MenuSocialMedia = styled.div`
	display: flex;
	width: fit-content;
	height: 6rem;
	align-items: center;
	@media(max-width: 1000px) {
		height: fit-content;
	}
`;

export const MenuSocialMediaA = styled.a`
	margin-right: 1.5rem;
`;

export const MenuContact = styled.div`
	display: flex;
	width: fit-content;
	height: 6rem;
	align-items: center;
`;

export const MenuText = styled.span`
	display: flex;
	font-size: 1.8rem;
	text-transform: uppercase;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: ${({ active }) => (active ? 'bold' : 400)};
	line-height: 2.5rem;
	white-space: nowrap;
	margin: 0 1rem;
	color: ${({ theme }) => theme.colors.white};

	&:hover {
		font-weight: bold;
	}
`;

export const MenuContactPhone = styled.span`
	display: flex;
	font-size: 1.8rem;
	text-transform: uppercase;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	white-space: nowrap;
	margin: 0 1rem;
	color: ${({ theme }) => theme.colors.white};
`;


export const ButtonDrawer = styled.button`
	display: flex;
`;

export const ButtonCloseDrawer = styled.button`
	display: flex;
	position: absolute;
	top: 0;
	right: 0;
	background-color: #97030C;
	padding: 2rem;
`;

export const MenuIconStyled = styled(MenuIcon)`
	color: #fff;
	font-size: 2rem;
`;
export const MenuCloseIconStyled = styled(CloseIcon)`
	color: #fff;
	font-size: 2rem;
`;
export const DrawerStyled = styled(Drawer)`
	.MuiPaper-root {
		position: relative;
		background-color: ${({ theme }) => theme.colors.primary};
		min-width: 100vw;

	}
`;

export const MenuTextMobile = styled.span`
	display: flex;
	font-size: 2rem;
	text-transform: uppercase;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: ${({ active }) => (active ? 'bold' : 400)};
	line-height: 2.5rem;
	white-space: nowrap;
	margin: 0 1rem;
	padding: 1.5rem;
	color: ${({ theme }) => theme.colors.white};

	&:hover {
		font-weight: bold;
	}
`;
export const MenuLinksMobile = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
	transform: translateY(-2rem);
`;
export const MenuLogoImageMobile = styled.img`
	transform: translateY(30%);
	z-index: 999;
	height: 15rem;
	width: auto;
`;

export const MenuMobile = styled.div`
	display: flex;
	width: 100%;
	height: 8rem;
	padding: 0 2rem;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.primary};
`;
