import AcaiBanana from '~/assets/images/products/acaibanana.png';
import AcaiGuarana from '~/assets/images/products/acaigurarana.png';
import AcaiMorango from '~/assets/images/products/acaimorango.png';
import AcaiZero from '~/assets/images/products/acaizero.png';
import CaldaAcai from '~/assets/images/products/caldaacai.png';
import CaldaCupuacu from '~/assets/images/products/caldacupuacu.png';
import SorveteAgua from '~/assets/images/products/sorveteagua.png';
import SorveteLeite from '~/assets/images/products/sorveteleite.png';

export default [
	{
		title: 'Açaí com Guaraná',
		slug: 'acai-com-guarana',
		subtitle: '250ml - 1L - 2L - 7L',
		text:
			'Delicioso Açaí cremoso feito com polpa de açaí com alto teor de sólidos, extrato e aroma natural de guaraná. Ideal para usar como base com outras combinações. Pronto para servir e desfrutar.',
		size: '25%',
		tag: 'açaí',
		img: AcaiGuarana,
		nutritionTableTitle: 'TABELA NUTRICIONAL',
		nutritionTable: [
			{
				title: 'Porção 250ml (200g)',
				table: {
					header: {
						key: '1 UNIDADE',
						value: 'QTD PORÇÃO',
						percent: '%V.D.*'
					},
					content: [
						{
							key: 'VALOR ENERGÉTICO',
							value: '180 kcal',
							percent: '9%'
						},
						{ key: 'CARBOIDRATOS', value: '34g', percent: '11%' },
						{ key: 'PROTEÍNAS', value: '1,3g', percent: '1,7%' },
						{
							key: 'GORDURAS TOTAIS',
							value: '5,1g',
							percent: '9,3%'
						},
						{ key: 'GORDURAS SATURADAS', value: '0', percent: '0' },
						{ key: 'GORDURAS TRANS', value: '0', percent: '**' },
						{
							key: 'FIBRA ALIMENTAR',
							value: '2,2g',
							percent: '8,9%'
						},
						{ key: 'SÓDIO', value: '4,7mg', percent: '0,2%' },
						{ key: 'CÁLCIO', value: '27mg', percent: '2,7%' },
						{ key: 'FERRO', value: '0,2mg', percent: '1,8%' },
						{ key: 'POTÁSSIO', value: '80mg', percent: '**' }
					],
					footer:
						'* % Valores Diários de referência com base em uma dieta de 2.000kcal ou 8.400kJ.\n\nSeus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas.Ingredientes: Polpa de açaí com alto teor de sólidos, água, açúcar, maltodextrina, amido modificado, emulsificante mono diglicerídeos de ácidos graxos estabilizante goma guar, acidulante ácido cítrico, estabilizante goma guar, estabilizante polidextrosse, estabilizante carboximetilcelulose, estabilizante goma xantana, emulsificante lecitina de soja, extrato de guaraná e aroma idêntico ao natural de guaraná. Não contém glúten.'
				}
			}
		]
	},
	{
		title: 'Açaí com Morango\n& Guaraná',
		subtitle: '250ml - 1L - 2L - 7L',
		slug: 'acai-com-morango-e-guarana',
		img: AcaiMorango,
		text:
			'Delicioso Açaí cremoso feito com polpa de açaí com alto teor de sólidos, morangos de verdade, extrato e aroma natural de guaraná. Muito indicado para quem gosta de uma delícia gelada com um leve toque ácido. Pronto para servir e desfrutar.',
		size: '25%',
		tag: 'açaí',
		nutritionTableTitle: 'TABELA NUTRICIONAL',
		nutritionTable: [
			{
				title: 'Porção 250ml (200g)',
				table: {
					header: {
						key: '1 UNIDADE',
						value: 'QTD PORÇÃO',
						percent: '%V.D.*'
					},
					content: [
						{
							key: 'VALOR ENERGÉTICO',
							value: '176 kcal',
							percent: '9%'
						},
						{ key: 'CARBOIDRATOS', value: '36g', percent: '11%' },
						{ key: 'PROTEÍNAS', value: '1,2g', percent: '1,7%' },
						{
							key: 'GORDURAS TOTAIS',
							value: '4,0g',
							percent: '7,2%'
						},
						{ key: 'GORDURAS SATURADAS', value: '0', percent: '0' },
						{ key: 'GORDURAS TRANS', value: '0', percent: '**' },
						{
							key: 'FIBRA ALIMENTAR',
							value: '2,2g',
							percent: '8,8%'
						},
						{ key: 'SÓDIO', value: '3,5mg', percent: '0,1%' },
						{ key: 'CÁLCIO', value: '24mg', percent: '2,4%' },
						{ key: 'FERRO', value: '0,3mg', percent: '2,1%' },
						{ key: 'POTÁSSIO', value: '119mg', percent: '**' }
					],
					footer:
						'* % Valores Diários de referência com base em uma dieta de 2.000kcal ou 8.400kJ.\n\nSeus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas.Ingredientes: Polpa de açaí com alto teor de sólidos, água, açúcar, polpa de morango, maltodextrina, amido modificado, emulsificante mono diglicerídeos de ácidos graxos estabilizante goma guar, acidulante ácido cítrico, estabilizante goma guar, estabilizante polidextrosa, estabilizante carboximetilcelulosa, estabilizante goma xantana, emulsificante lecitina de soja, extrato de guaraná e aroma idêntico ao natural de guaraná. Não contém glúten.'
				}
			}
		]
	},
	{
		title: 'Açaí com Banana\n& Guaraná',
		subtitle: '250ml - 400ml - 1L - 2L - 7L',
		slug: 'acai-com-banana-e-guarana',
		tag: 'açaí',
		img: AcaiBanana,
		text:
			'Delicioso Açaí cremoso feito com polpa de açaí com alto teor de sólidos, purê de banana, extrato e aroma natural de guaraná. O clássico que popularizou o açaí cremoso. Pronto para servir e desfrutar.',
		size: '25%',
		nutritionTableTitle: 'TABELA NUTRICIONAL',
		nutritionTable: [
			{
				title: 'Porção 250ml (200g)',
				table: {
					header: {
						key: '1 UNIDADE',
						value: 'QTD PORÇÃO',
						percent: '%V.D.*'
					},
					content: [
						{
							key: 'VALOR ENERGÉTICO',
							value: '177 kcal',
							percent: '9%'
						},
						{ key: 'CARBOIDRATOS', value: '36g', percent: '12%' },
						{ key: 'PROTEÍNAS', value: '1,6g', percent: '2,2%' },
						{
							key: 'GORDURAS TOTAIS',
							value: '4,1g',
							percent: '7,4%'
						},
						{
							key: 'GORDURAS SATURADAS',
							value: '0',
							percent: '**'
						},
						{ key: 'GORDURAS TRANS', value: '0', percent: '**' },
						{
							key: 'FIBRA ALIMENTAR',
							value: '2,6g',
							percent: '10,5%'
						},
						{ key: 'SÓDIO', value: '3,8mg', percent: '0,2%' },
						{ key: 'CÁLCIO', value: '23mg', percent: '2,3%' },
						{ key: 'FERRO', value: '0,3mg', percent: '2,30%' },
						{ key: 'POTÁSSIO', value: '232mg', percent: '**' }
					],
					footer:
						'* % Valores Diários de referência com base em uma dieta de 2.000kcal ou 8.400kJ.\n\nSeus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas.Ingredientes: Polpa de açaí com alto teor de sólidos, água, açúcar, polpa de banana, maltodextrina, amido modificado, emulsificante mono diglicerídeos de ácidos graxos estabilizante goma guar, acidulante ácido cítrico, estabilizante goma guar, estabilizante polidextrosa, estabilizante carboximetilcelulosa, estabilizante goma xantana, emulsificante lecitina de soja, extrato de guaraná e aroma idêntico ao natural de guaraná. Não contém glúten.'
				}
			}
		]
	},
	{
		title: 'Açaí Zero',
		subtitle: '250ml - 1L - 2L - 7L',
		slug: 'acai-zero',
		tag: 'açaí',
		img: AcaiZero,
		text:
			'Delicioso Açaí cremoso feito com polpa de açaí com alto teor de sólidos, adoçantes naturais (Stevia, Eritritol e Maltitol), extrato e aroma natural de guaraná. Um perfeito balanço entre sabor e saúde! Pronto para servir e desfrutar.',
		size: '25%',
		nutritionTableTitle: 'TABELA NUTRICIONAL',
		nutritionTable: [
			{
				title: 'Porção 250ml (200g)',
				table: {
					header: {
						key: '1 UNIDADE',
						value: 'QTD PORÇÃO',
						percent: '%V.D.*'
					},
					content: [
						{
							key: 'VALOR ENERGÉTICO',
							value: '118 kcal',
							percent: '3%'
						},
						{ key: 'CARBOIDRATOS', value: '32g', percent: '5%' },
						{ key: 'POLIÓIS', value: '22g', percent: '**' },
						{ key: 'PROTEÍNAS', value: '2g', percent: '2%' },
						{
							key: 'GORDURAS TOTAIS',
							value: '3g',
							percent: '5%'
						},
						{ key: 'GORDURAS SATURADAS', value: '0', percent: '0' },
						{ key: 'GORDURAS TRANS', value: '0', percent: '**' },
						{
							key: 'FIBRA ALIMENTAR',
							value: '5,6g',
							percent: '11%'
						},
						{ key: 'SÓDIO', value: '92mg', percent: '2%' }
					],
					footer:
						'* % Valores Diários de referência com base em uma dieta de 2.000kcal ou 8.400kJ.\n\nSeus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas.Ingredientes: Polpa de açaí com alto teor de sólidos, maltitol, eritritol, polidextrose, maltodextrina, mix de adoçantes naturais (Stévia, Taumatina e Xilitol), acidulante ácido cítrico, estabilizantes goma guar e xantana, emulsificantes lecitina de soja, mono diglicerídeos de ácidos graxos e carboximetilcelulose sódica, corantes naturais caramelo e carmim de cochonilha e aroma de guaraná idêntico ao natural. Não contém glúten.'
				}
			}
		]
	},
	{
		title: 'Sorvetes ao Leite',
		subtitle: '150ml - 1L - 2L - 7L',
		slug: 'sorvetes-ao-leite',
		tag: 'sorvete',
		img: SorveteLeite,
		text:
			'Feitos com leite integral, a nossa linha de sorvetes base leite se caracterizam por um alto teor de gordura láctea o que dá uma cremosidade única. Atualmente temos mais de 25 sabores de altíssima qualidade. Saiba mais.',
		size: '25%',
		nutritionTable: [
			{
				title: 'SABORES',
				table: {
					content: [
						{ key: 'Abacaxi' },
						{ key: 'Baunilha' },
						{ key: 'Bombon' },
						{ key: 'Brigadeiro' },
						{ key: 'Café Capuccino' },
						{ key: 'Chiclete Tutti Frutti' },
						{ key: 'Chocolate Branco' },
						{ key: 'Chocolate' },
						{ key: 'Coco' },
						{ key: 'Cupuaçu' },
						{ key: 'Doce de Leite' },
						{ key: 'Flocos' },
						{ key: 'Flor de Leite' },
						{ key: 'Iogurte c/Morango' },
						{ key: 'Leite Condensado' },
						{ key: 'Mousse de Limão' },
						{ key: 'Mousse de Maracujá' },
						{ key: 'Morango' },
						{ key: 'Passas ao Rum' }
					]
				}
			}
		]
	},
	{
		title: 'Sorvetes Veganos',
		subtitle: '150ml - 1L - 2L - 7L',
		slug: 'sorvetes-veganos',
		tag: 'sorvete',
		img: SorveteAgua,
		text:
			'Os nossos sorvetes base água não contém leite e tampouco produtos de origem animal. A cremosidade destas delícias está marcada pelo uso de fibras naturais de origem vegetal. Contamos com mais de 10 sabores. Confira!',
		size: '25%',
		nutritionTable: [
			{
				title: 'SABORES',
				table: {
					content: [
						{ key: 'Abacaxi' },
						{ key: 'Cajá' },
						{ key: 'Cupuaçu' },
						{ key: 'ChocoVegan' },
						{ key: 'Graviola' },
						{ key: 'Limão' },
						{ key: 'Maracujá' },
						{ key: 'Marapitaya (Maracujá + Pitaya)' },
						{ key: 'Morango' }
					]
				}
			}
		]
	},
	{
		title: 'Calda Soft\nde Açaí',
		subtitle: '5 Kg',
		slug: 'calda-soft-de-acai',
		tag: 'calda',
		img: CaldaAcai,
		text:
			'Calda preparada a base de açaí com alto teor de sólidos, pure de banana, extrato e aroma natural de guaraná. A base ideal para a sua máquina de sorvete Soft!',
		size: '25%',
		nutritionTableTitle: 'TABELA NUTRICIONAL',
		nutritionTable: [
			{
				title: 'Porção 250ml (200g)',
				table: {
					header: {
						key: '1 UNIDADE',
						value: 'QTD PORÇÃO',
						percent: '%V.D.*'
					},
					content: [
						{
							key: 'VALOR ENERGÉTICO',
							value: '177 kcal',
							percent: '9%'
						},
						{ key: 'CARBOIDRATOS', value: '36g', percent: '12%' },
						{ key: 'PROTEÍNAS', value: '1,6g', percent: '2,2%' },
						{
							key: 'GORDURAS TOTAIS',
							value: '4,1g',
							percent: '7,4%'
						},
						{ key: 'GORDURAS SATURADAS', value: '0', percent: '0' },
						{ key: 'GORDURAS TRANS', value: '0', percent: '**' },
						{
							key: 'FIBRA ALIMENTAR',
							value: '2,6g',
							percent: '10,5%'
						},
						{ key: 'SÓDIO', value: '3,8mg', percent: '0,2%' },
						{ key: 'CÁLCIO', value: '23mg', percent: '2,3%' },
						{ key: 'FERRO', value: '0,3mg', percent: '2,3%' },
						{ key: 'POTÁSSIO', value: '232mg', percent: '**' }
					],
					footer:
						'* % Valores Diários de referência com base em uma dieta de 2.000kcal ou 8.400kJ.\n\nSeus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas. Ingredientes: Polpa de açaí com alto teor de sólidos, água, açúcar, polpa de banana, maltodextrina, amido modificado, emulsificante mono diglicerídeos de ácidos graxos estabilizante goma guar, acidulante ácido cítrico, estabilizante goma guar, estabilizante polidextrosa, estabilizante carboximetilcelulosa, estabilizante goma xantana, emulsificante lecitina de soja, extrato de guaraná e aroma idêntico ao natural de guaraná. Não contém glúten.'
				}
			}
		]
	},
	{
		title: 'Calda Soft\nde Cupuaçu',
		subtitle: '5 Kg',
		slug: 'calda-soft-de-cupuacu',
		tag: 'calda',
		img: CaldaCupuacu,
		text:
			'Calda preparada a base de polpa integral de cupuaçu e leite. A base ideal para a sua máquina de sorvete Soft! O irmão perfeito do açaí! Experimente.',
		size: '25%',
		nutritionTableTitle: 'TABELA NUTRICIONAL',
		nutritionTable: [
			{
				title: 'Porção 250ml (200g)',
				table: {
					header: {
						key: '1 UNIDADE',
						value: 'QTD PORÇÃO',
						percent: '%V.D.*'
					},
					content: [
						{
							key: 'VALOR ENERGÉTICO',
							value: '110 kcal',
							percent: '6%'
						},
						{ key: 'CARBOIDRATOS', value: '15g', percent: '5%' },
						{ key: 'AÇÚCARES', value: '13g', percent: '**' },
						{ key: 'PROTEÍNAS', value: '1,7g', percent: '2%' },
						{
							key: 'GORDURAS TOTAIS',
							value: '5g',
							percent: '9%'
						},
						{ key: 'GORDURAS SATURADAS', value: '0', percent: '0' },
						{ key: 'GORDURAS TRANS', value: '0', percent: '**' },
						{
							key: 'FIBRA ALIMENTAR',
							value: '0,6g',
							percent: '2%'
						},
						{ key: 'SÓDIO', value: '39mg', percent: '2%' }
					],
					footer:
						'* % Valores Diários de referência com base em uma dieta de 2.000kcal ou 8.400kJ.\n\nSeus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas. Ingredientes: água, açúcar, leite integral em pó, polpa integral de cupuaçu, soro de leite, gordura vegetal, glucose de milho, maltodextrina, amido modificado, emulsificante mono diglicerídeos de ácidos graxos estabilizante goma guar, acidulante ácido cítrico, estabilizante goma guar, estabilizante polidextrosa, estabilizante carboximetilcelulosa, estabilizante goma xantana, emulsificante lecitina de soja. Não contém glúten.'
				}
			}
		]
	}
];
