import styled from 'styled-components';

export const SectionCategoryProducts = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	z-index: 2;
`;

export const CategoryProductsContainer = styled.div`
	display: flex;
	width: 100%;
	padding: 5rem 2rem;
	background-color: ${({ theme }) => theme.colors.primary};
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
	@media(max-width: 1000px) {
		padding-bottom: 11rem;
	}
`;

export const CategoryProductsTitle = styled.h3`
	display: flex;
	font-size: 3.8rem;
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 1.8rem;
	color: ${({ theme }) => theme.colors.white};
	text-align: center;

	@media(max-width: 1000px) {
		font-size: 2.8rem;
	}
`;

export const CategoryProductsText = styled.p`
	display: flex;
	font-size: 1.8rem;
	line-height: 2.5rem;
	margin-bottom: 1.8rem;
	color: ${({ theme }) => theme.colors.white};
	text-align: justify;
	@media(max-width: 1000px) {
		font-size: 1.6rem;
	}
`;

export const ButtonCategory = styled.button`
	display: flex;
	width: 20rem;
	background-color: ${({ selected, theme }) =>
		selected ? theme.colors.primaryLight : theme.colors.white};
	font-size: 2.4rem;
	line-height: 3.6rem;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
	color: ${({ selected, theme }) =>
		selected ? theme.colors.white : theme.colors.primaryDark};
	border-radius: 1rem;
	padding: 1rem 2rem;
	align-content: center;
	align-items: center;
	justify-content: center;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
	max-width: 109rem;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: center;
	align-items: center;
	padding-bottom: ${({ paddingBottom }) => paddingBottom};
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;

export const Column = styled.div`
	display: flex;
	flex-grow: 1;
	width: ${({ size }) => size};
	padding: 0.5rem;
	padding-bottom: ${({ paddingBottom }) => String(paddingBottom) || '0'}rem;
	justify-content: ${({ justifyContent }) => justifyContent || 'center'};
`;
