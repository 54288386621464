import styled from 'styled-components';

import { ReactComponent as Wave } from '~/assets/images/wave.svg';

export const StyledWave = styled(Wave)`
	width: 100vw;
	fill: ${({ fill }) => fill || '#fff'};
	margin-bottom: -3px;
	background-color: #db2430;
`;

export const BannerSection = styled.section`
	display: flex;
	width: 100vw;
`;
export const BannerColorSection = styled.section`
	display: flex;
	width: 100%;
	padding-top: 10rem;
	background-color: #db2430;
	z-index: 1;
`;

export const BannerImg = styled.img`
	display: flex;
	width: 100vw;
	height: auto;
`;

export const Color = styled.div`
	display: flex;
	width: 100%;
	height: 20rem;
	background-color: #db2430;
	@media (max-width: 1000px) {
		height: 8rem;
	}
`;
