import React from 'react';

import {
	SectionInfoContact,
	MicroWave,
	InfoContactContent
} from '~/common/styleds/components/infoContact';

const InfoContact = () => {
	return (
		<SectionInfoContact>
			<MicroWave style={{ width: '100vw' }} />
			<InfoContactContent>
				{/* <Grid container spacing={3}>
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center'
						}}
					>
						<BoxInfoContactText>
							<TitleInfoContact>
								<FormattedMessage id='contact.user1.title' />
							</TitleInfoContact>
							<TextInfoContact>
								<FormattedMessage id='contact.user1.text' />
							</TextInfoContact>
							<LinkInfoContact href='https://api.whatsapp.com/send?1=pt_BR&phone=5561983353940'>
								<Icon
									name='whatsapp'
									color={colors.white}
									size={28}
									marginRight='1rem'
								/>
								<FormattedMessage id='contact.user1.phone' />
							</LinkInfoContact>
						</BoxInfoContactText>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center'
						}}
					>
						<BoxInfoContactText>
							<TitleInfoContact>
								<FormattedMessage id='contact.user2.title' />
							</TitleInfoContact>
							<TextInfoContact>
								<FormattedMessage id='contact.user2.text' />
							</TextInfoContact>
							<LinkInfoContact href='https://api.whatsapp.com/send?1=pt_BR&phone=5561993771045'>
								<Icon
									name='whatsapp'
									color={colors.white}
									size={28}
									marginRight='1rem'
								/>
								<FormattedMessage id='contact.user2.phone' />
							</LinkInfoContact>
						</BoxInfoContactText>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={4}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center',
							alignItems: 'center'
						}}
					>
						<BoxInfoContactText>
							<TitleInfoContact>
								<FormattedMessage id='contact.sac.title' />
							</TitleInfoContact>
							<TextInfoContact>
								<FormattedMessage id='contact.sac.text' />
							</TextInfoContact>
							<TextInfoContact>
								<FormattedMessage id='contact.sac.email' />
							</TextInfoContact>
						</BoxInfoContactText>
					</Grid>
				</Grid> */}
			</InfoContactContent>
		</SectionInfoContact>
	);
};

export default InfoContact;
