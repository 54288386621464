import React from 'react';

import {
	BannerColorSection,
	StyledWave
} from '~/common/styleds/components/bannerImg';

const BannerColor = ({ fill = '#ffffff' }) => {
	return (
		<BannerColorSection>
			<StyledWave fill={fill} />
		</BannerColorSection>
	);
};

export default BannerColor;
