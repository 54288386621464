import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as MicroWave } from '~/assets/images/microwave.svg';
// import { ReactComponent as Wave } from '~/assets/images/wave.svg';
import { useTheme } from '~/common/styleds';
import {
	SectionHistory,
	HistoryContainer,
	HistoryContent,
	HistoryTitle,
	HistoryParagraph
} from '~/common/styleds/components/history';

const ResumeHistory = () => {
	const { colors } = useTheme();
	return (
		<SectionHistory>
			<HistoryContainer>
				<HistoryContent style={{ padding: 20 }}>
					<HistoryTitle>
						<FormattedMessage id='about.history.title' />
					</HistoryTitle>
					<HistoryParagraph>
						<FormattedMessage id='about.history.p1' />
					</HistoryParagraph>
					<HistoryParagraph>
						<FormattedMessage id='about.history.p2' />
					</HistoryParagraph>
					<HistoryParagraph>
						<FormattedMessage id='about.history.p3' />
					</HistoryParagraph>
					<HistoryParagraph>
						<FormattedMessage id='about.history.p4' />
					</HistoryParagraph>
				</HistoryContent>
			</HistoryContainer>
			<MicroWave
				fill={colors.white}
				style={{ width: '100vw', marginTop: -3 }}
			/>
		</SectionHistory>
	);
};

export default ResumeHistory;
