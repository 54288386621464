import React from 'react';
import { Helmet } from 'react-helmet';

import BannerFooter from '~/assets/images/bannerFooter/principal.png';
import { Header, Banner, Footer, ResumeProducts } from '~/common/components';
import { Container } from '~/common/styleds/home/home';

const Home = () => {
	return (
		<Container>
			<Helmet>
				<title>La Nieve Açaí e Sorvetes - Principal</title>
				<meta
					name='description'
					content='Bem vindo à La Nieve! Somos um dos maiores fornecedores de açai e sorvetes do Distrito Federal. Conheça a nossa deliciosa variedade de produtos.'
				/>
			</Helmet>
			<Header />
			<Banner />
			<ResumeProducts />
			<Footer img={BannerFooter} path='/produtos' />
		</Container>
	);
};

export default Home;
