import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import logo from '~/assets/images/logo.svg';
// import { ReactComponent as Wave } from '~/assets/images/wave.svg';
import BannerImage from '~/common/components/banner/BannerImage.jsx';
import { useTheme } from '~/common/styleds';
import {
	FooterSection,
	FooterContent,
	FooterColumn,
	LogoImg,
	FooterTitle,
	FooterText,
	Copyright,
	CopyrightText
} from '~/common/styleds/components/footer';
// import { GoContactSection } from '~/common/styleds/components/goContact';
import {
	MenuSocialMedia,
	MenuSocialMediaA
} from '~/common/styleds/components/header';
import { useWindowSize } from '~/hooks/window';

import Icon from '../icon/Icon';

const Footer = ({ img, path }) => {
	const { colors } = useTheme();
	const intl = useIntl();
	const { width } = useWindowSize();
	return (
		<FooterSection>
			{img && (
				<Link to={path} onClick={() => window.scrollTo(0, 0)}>
					<BannerImage img={img} />
				</Link>
			)}
			<FooterContent>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={4}>
						<FooterColumn>
							<Link to='/'>
								<LogoImg
									src={logo}
									alt={intl.formatMessage({
										id: 'footer.altLogo'
									})}
								/>
							</Link>
						</FooterColumn>
					</Grid>
					{width <= 1000 && (
						<>
							<FooterColumn>
								<a
									href='https://api.whatsapp.com/send?1=pt_BR&phone=5561981789489'
									style={{
										backgroundColor: 'white',
										borderRadius: 10,
										padding: '5px 25px'
									}}
								>
									<FooterText
										style={{
											color: colors.primary,
											fontWeight: 'bold'
										}}
									>
										<Icon
											name='whatsapp'
											color={colors.primary}
											size={36}
											marginRight='1rem'
										/>
										WHATSAPP
									</FooterText>
								</a>
							</FooterColumn>
							<Grid item xs={12} sm={12} md={4}>
								<FooterColumn>
									<FooterText>
										<FormattedMessage id='footer.address' />
									</FooterText>
								</FooterColumn>
							</Grid>
							<Grid item xs={12} sm={12} md={4}>
								<FooterColumn>
									<MenuSocialMedia>
										<MenuSocialMediaA
											href='https://www.facebook.com/LaNieveacai'
											target='blank'
										>
											<Icon
												name='facebook'
												color={colors.white}
												size={36}
											/>
										</MenuSocialMediaA>
										<MenuSocialMediaA
											href='https://www.instagram.com/lanieveacai'
											target='blank'
										>
											<Icon
												name='instagram'
												color={colors.white}
												size={36}
											/>
										</MenuSocialMediaA>
									</MenuSocialMedia>
								</FooterColumn>
							</Grid>
						</>
					)}

					{width > 1000 && (
						<>
							<Grid item xs={12} sm={12} md={4}>
								<FooterColumn>
									<FooterTitle>
										<FormattedMessage id='footer.place' />
									</FooterTitle>
									<FooterText>
										<FormattedMessage id='footer.addressTitle' />
									</FooterText>
									<FooterText>
										<FormattedMessage id='footer.address' />
									</FooterText>
								</FooterColumn>
							</Grid>
							<Grid item xs={12} sm={12} md={4}>
								<FooterColumn>
									<FooterTitle>
										<FormattedMessage id='footer.contact' />
									</FooterTitle>
									<FooterText bold>
										<FormattedMessage id='footer.sac' />
									</FooterText>
									<FooterText>
										<FormattedMessage id='footer.phone' />
									</FooterText>

									<a
										href='https://api.whatsapp.com/send?1=pt_BR&phone=5561981789489'
										style={{
											backgroundColor: 'white',
											borderRadius: 10,
											padding: '5px 25px'
										}}
									>
										<FooterText
											style={{
												color: colors.primary,
												fontWeight: 'bold'
											}}
										>
											<Icon
												name='whatsapp'
												color={colors.primary}
												size={36}
												marginRight='1rem'
											/>
											WHATSAPP
										</FooterText>
									</a>
								</FooterColumn>
							</Grid>
						</>
					)}
				</Grid>
			</FooterContent>
			{width > 1000 && (
				<Copyright style={{ flexDirection: 'column' }}>
					<MenuSocialMedia>
						<MenuSocialMediaA
							href='https://www.facebook.com/LaNieveacai'
							target='blank'
						>
							<Icon
								name='facebook'
								color={colors.white}
								size={36}
							/>
						</MenuSocialMediaA>
						<MenuSocialMediaA
							href='https://www.instagram.com/lanieveacai'
							target='blank'
						>
							<Icon
								name='instagram'
								color={colors.white}
								size={36}
							/>
						</MenuSocialMediaA>
					</MenuSocialMedia>
					<CopyrightText>
						<FormattedMessage id='footer.copyright' />
					</CopyrightText>
				</Copyright>
			)}
		</FooterSection>
	);
};

export default Footer;
