import React from 'react';

import PropTypes from 'prop-types';

const Icon = ({ name, size, color, marginRight, marginLeft }) => {
	return (
		<i
			className={`icon-${name}`}
			style={{ fontSize: size, color, marginRight, marginLeft }}
		/>
	);
};

Icon.propTypes = {
	name: PropTypes.string.isRequired,
	size: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired
};

export default Icon;
