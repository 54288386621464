import React from 'react';

import {
	BannerSection,
	BannerImg
} from '~/common/styleds/components/bannerImg';

const BannerImage = ({ img }) => {
	return (
		<BannerSection>
			<BannerImg src={img} />
		</BannerSection>
	);
};

export default BannerImage;
