import { useContext } from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeContext } from 'styled-components';

export const styledTheme = {
	colors: {
		primary: '#C80410',
		primaryDark: '#A60004',
		primaryLight: '#DB2430',
		primaryHover: '#f5de5d',
		secondary: '#C182DF',

		white: '#ffffff',

		textDark: '#593A3A',
		textMinDark: '#5A5A5A',
		titleInWhite: '#A60004',

		inputBorder: '#897878'
	}
};


export const muiTheme = createMuiTheme({
	palette: {
		primary: {
			main: styledTheme.colors.primary,
			contrastText: styledTheme.colors.textInPrimary
		},
		secondary: {
			main: styledTheme.colors.secondary,
			contrastText: styledTheme.colors.textInSecondary
		}
	}
});

export const theme = {
	...styledTheme,
	...muiTheme
};

export const useTheme = () => useContext(ThemeContext);
