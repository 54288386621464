/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useToasts } from 'react-toast-notifications';

import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import {
	SectionFormContact,
	FormContactContainer,
	BoxText,
	Title,
	Text,
	Form,
	Input,
	InputMask,
	TextArea,
	Button
} from '~/common/styleds/components/formContact';

const isNotFilledTel = (v) =>
	v && v.indexOf('_') === -1 ? undefined : 'O campo é obrigatório.';

const FormContact = () => {
	const { handleSubmit, register, reset } = useForm();
	const { addToast } = useToasts();

	const onSubmit = async (values) => {
		try {
			await axios.post(
				'https://sheet.best/api/sheets/ed27c16f-f75d-4381-a19c-c891ec1af12a',
				values
			);
			addToast('Contato enviado com sucesso!', {
				appearance: 'success'
			});
			reset();
		} catch (error) {
			addToast('Aconteceu um erro ao enviar a mensagem de contato', {
				appearance: 'error'
			});
		}
	};

	return (
		<SectionFormContact>
			<FormContactContainer>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<div style={{ flexGrow: 1 }}>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<BoxText>
									<Title>
										<FormattedMessage id='contact.form.title' />
									</Title>
									<Text>
										<FormattedMessage id='contact.form.text' />
									</Text>
								</BoxText>
							</Grid>
							<Grid item md={6} xs={12} sm={12}>
								<Input
									ref={register({ required: true })}
									name='responsible'
									placeholder='Nome do Responsável'
								/>
							</Grid>
							<Grid item md={6} xs={12} sm={12}>
								<Input
									ref={register({ required: true })}
									name='enterprise'
									placeholder='Nome da empresa'
								/>
							</Grid>
							<Grid item md={4} xs={12} sm={12}>
								<Input
									ref={register({ required: true })}
									name='email'
									placeholder='Email'
								/>
							</Grid>
							<Grid item md={4} xs={12} sm={12}>
								<InputMask
									name='phone'
									type='cel'
									placeholder='Telefone fixo'
									inputRef={register({
										validate: {
											phoneRequired: isNotFilledTel
										}
									})}
									mask='(99) 9999-9999'
								/>
							</Grid>
							<Grid item md={4} xs={12} sm={12}>
								<InputMask
									name='cel'
									type='cel'
									placeholder='Telefone celular'
									inputRef={register({
										validate: {
											phoneRequired: isNotFilledTel
										}
									})}
									mask='(99) 9 9999-9999'
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<TextArea
									rows={6}
									ref={register({ required: true })}
									name='message'
									placeholder='Mensagem'
								/>
							</Grid>
							<Grid container direction='row' justify='flex-end'>
								<Button>Enviar</Button>
							</Grid>
						</Grid>
					</div>
				</Form>
			</FormContactContainer>
		</SectionFormContact>
	);
};

export default FormContact;
