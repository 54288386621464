import React from 'react';
import { Helmet } from 'react-helmet';

import { Header, Footer } from '~/common/components';
import {
	Container,
	Section404,
	Box,
	Text,
	ErrorCode,
	ErrorTitle,
	ErrorText
} from '~/common/styleds/errors/error404';

const Error404 = () => {
	return (
		<Container>
			<Helmet>
				<title>La Nieve Açaí e Sorvetes - Principal</title>
				<meta
					name='description'
					content='Bem vindo à La Nieve! Somos um dos maiores fornecedores de açaí e sorvetes do Distrito Federal. Conheça a nossa deliciosa variedade de produtos.'
				/>
			</Helmet>
			<Header />
			<Section404>
				<Box>
					<Text>Erro</Text>
					<ErrorCode>404</ErrorCode>
				</Box>
				<Box>
					<ErrorTitle>PÁGINA NÃO ENCONTRADA</ErrorTitle>
					<ErrorText>
						A página que você procura não existe ou não está
						disponível.
					</ErrorText>
				</Box>
			</Section404>
			<Footer />
		</Container>
	);
};

export default Error404;
