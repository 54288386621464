import React from 'react';
import { Helmet } from 'react-helmet';

import BannerFooter from '~/assets/images/bannerFooter/quemsomosecontato.png';
import {
	Header,
	BannerColor,
	Footer,
	AboutHistory,
	Mission
} from '~/common/components';
import { Container } from '~/common/styleds/about/about';

const About = () => {
	return (
		<Container>
			<Helmet>
				<title>La Nieve Açaí e Sorvetes - Sobre a Empresa</title>
				<meta
					name='description'
					content='Fundada em Brasília em 1994, La Nieve iniciou as suas atividades na fabricação de sorvetes e coberturas finas, conquistando em pouco tempo um grande prestígio e o reconhecimento dos consumidores mais exigentes do Distrito Federal.'
				/>
			</Helmet>
			<Header />
			<BannerColor />
			<AboutHistory />
			<Mission />
			<Footer img={BannerFooter} path='/produtos' />
		</Container>
	);
};

export default About;
