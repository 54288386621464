import React from 'react';
import { Helmet } from 'react-helmet';

import BannerFooter from '~/assets/images/bannerFooter/produtos.png';
import {
	Header,
	Footer,
	CategoryProducts,
	BannerColor
} from '~/common/components';
import { Container } from '~/common/styleds/products/products';

const Products = () => {
	return (
		<Container>
			<Helmet>
				<title>La Nieve Açaí e Sorvetes - Produtos</title>
				<meta
					name='description'
					content='Bem vindo à página de produtos La Nieve! Conheça a nossa variedade de açaís e sorvetes. São mais de 25 sabores diferentes e você ainda pode personalizar o sabor do seu sorvete.'
				/>
			</Helmet>
			<Header />
			<BannerColor fill='#C80410' />
			<CategoryProducts />
			<Footer img={BannerFooter} path='/contato' />
		</Container>
	);
};

export default Products;
